import React from "react";
import Hamburger from "./Hamburger";
import { Helmet } from "react-helmet";
import "./Header.css";
import { Link } from "gatsby";

function Header() {
  return (
    <>
      <Helmet>
        {/* General tags */}
        <title>Michelangelo Alesi</title>
        <meta
          name="description"
          content={"Michelangelo Alesi - Indipendent Front-End developer"}
        />
        <meta name="title" content={"Michelangelo Alesi"} />
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      </Helmet>
      <div className="Header__container">
        <div className="Header__item site-name">
          <h1>
            <Link to="/">
              michelangelo
              <br />
              alesi
            </Link>
          </h1>
        </div>
        <div className="Header__item main-menu">
          <ul>
            <li>
              <a href="https://portfolio.michelangeloalesi.it">works</a>
            </li>

            <li>
              <a target="_blank" href="michelangelo-alesi-curriculum-vitae.pdf">
                curriculum
              </a>
            </li>
            <li>
              <Link to="info">info</Link>
            </li>
          </ul>
        </div>
        <Hamburger />
      </div>
    </>
  );
}

export default Header;
