import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";

import "./Info.css";

function Info({ open }) {
  
  return (
    <CSSTransition in={open} timeout={200} classNames="Info" unmountOnExit>
      <div className="Info__container">
        <div className="Info__item meta">
          <h1>info</h1>
        </div>
        <div className="Info__item short-bio">
          <p>
            Web Developer<br/>
            <br />
            <br />
            <br />
            currently full-time Front-End Engineer @{" "}
            <a href="https://www.be-tse.it/it/">Be Digitech Solutions</a>.
            <br />
            <br />
            previously:
            <br />
            <br />
            Front-End Developer @{" "}
            <a href="https://www.nautes.com/">Nautes</a>.
            <br />
            tutor @{" "}
            <a href="https://www.accademiadiurbino.it/">
              Accademia di Belle Arti di Urbino
            </a>
            .
            <br />
            <br />
            Take a look at my{" "}
            <a href="http://portfolio.michelangeloalesi.it">portfolio</a>.
          </p>
        </div>
      </div>
    </CSSTransition>
  );
}

export default Info;
