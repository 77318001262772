import React, { useState, useEffect } from "react";
import "./Hamburger.css";
import Info from "./Info";

function Hamburger() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.querySelector(".Footer__container").style.zIndex = "-1"
    } else {
      document.querySelector(".Footer__container").style.zIndex = "0"

    }
},[open])

  return (
    <>
      <div
        className={`hamburger__container rotating ${open ? "__invert"  : ""}`}
        onClick={() => {
          setOpen(!open);
          console.log("opene");
        }}
      >
              <div className={`hamburger__row ${open ? "__invert"  : ""}`}></div>
        <div className={`hamburger__row ${open ? "__invert"  : ""}`}></div>
        <div className={`hamburger__row ${open ? "__invert"  : ""}`}></div>
      </div>
      <Info open={open} />
    </>
  );
}

export default Hamburger;
