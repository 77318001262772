import React from "react";
import { SocialIcon } from "react-social-icons";

import "./Footer.css";

function Footer() {
  return (
    <div className="Footer__container">
      <div className="Footer__item">
        <a href="https://portfolio.michelangeloalesi.it">
          <h1>Full Work List</h1>
        </a>
      </div>
      <div className="Footer__item social">
        <SocialIcon
          url="https://www.linkedin.com/in/michelangelo-alesi-8442b1142/"
          bgColor="#000"
        />
        <SocialIcon url="mailto:hello@michelangeloalesi.it" bgColor="#000" />
        <SocialIcon url="https://github.com/michintosh" bgColor="#000" />
        <SocialIcon url="https://michintosh.itch.io/" bgColor="#000" />
      </div>
    </div>
  );
}

export default Footer;
