import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const Layout = ({ children }) => {
  return (
    <main>
      <Header />
      <div style={{ padding: "10px", height:"100vh", overflow:"auto" }}>{children}</div>
      <Footer />
    </main>
  );
};

export default Layout;
